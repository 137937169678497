import { sessionConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case sessionConstants.SESSION_CORE:
    case sessionConstants.SESSION_ACCOUNT:
    case sessionConstants.SESSION_USER:
      return Object.assign({}, state, action);
    case sessionConstants.END_SESSION:
      return {};
    default:
      return state;
  }
};
