import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { alert } from '../../actions';
import { alertConstants } from '../../constants';
import './alert.css';

const AlertComponent = props => {
  const heading = props.alert.type === alertConstants.ERROR ? <Alert.Heading>Oops!</Alert.Heading> : <Alert.Heading>Yay!</Alert.Heading>;
  const variant = props.alert.type === alertConstants.ERROR ? 'danger' : 'success';

  return (
    <Alert className="alert-component" variant={variant} onClose={ () => props.clear() } dismissible>
      {heading}
      <p>{props.alert.message}</p>
    </Alert>
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  clear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
