import { loadAnimationConstants } from '../constants';

const begin = (message) => {
  return dispatch => {
    return dispatch({
      type: loadAnimationConstants.LOADING_BEGIN,
      message
    });
  };
}

const end = () => {
  return dispatch => {
    return dispatch({ type: loadAnimationConstants.LOADING_END });
  };
}

export const loading = {
  begin,
  end
};
