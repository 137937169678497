import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './masonry.css';

class MasonryComponent extends Component{
	constructor(props){
		super(props);
		this.state = { columns: 5 };
		this.onResize = this.onResize.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.mapChildren = this.mapChildren.bind(this);
	}

	componentDidMount(){
		this.onResize();
		window.addEventListener('resize', this.onResize);
	}

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

	getColumns(w) {
		return this.props.brakePoints.reduceRight((p, c, i) => {
			return c < w ? p : i;
		}, this.props.brakePoints.length) + 1;
	}

	onResize() {
		const columns = this.getColumns(this.refs.Masonry.offsetWidth);

    if (columns !== this.state.columns) {
      this.setState({columns: columns});
    }
	}

	mapChildren() {
		let col = [];
		const numC = this.state.columns;
		for(let i = 0; i < numC; i++){
			col.push([]);
		}
		return this.props.children.reduce((p, c, i) => {
			p[i%numC].push(
        <div key={i} className='tile'>
          {c}
        </div>
      );
			return p;
		}, col);
	}

	render() {
		return (
			<div className="masonry" ref="Masonry">
				{
          this.mapChildren().map((col, ci) => {
  					return (
  						<div className="column" key={ci} >
  							{ col.map((child) => child) }
  						</div>
  					)
  				})
        }
			</div>
		);
	}
}

MasonryComponent.defaultProps = {
  brakePoints: [510, 775, 1024]
};

MasonryComponent.propTypes = {
  brakePoints: PropTypes.array,
  children: PropTypes.node.isRequired
};

export default MasonryComponent
