const GetPage = async (core, account, site, id) => {
  const { requests } = core;

  try {
    const res = await requests.page.get(account, site, id);

    if (Array.isArray(res)) {
      return Promise.resolve(res.map(m => m.export()));
    }
    return Promise.resolve(res.export());
  } catch (e) {
    return Promise.reject(e);
  }
}

const CreatePage = async (core, data, account, site) => {
  const {
    models,
    requests
  } = core;

  if (account === undefined) {
    return Promise.reject(new Error('account id is required.'));
  }

  if (site === undefined) {
    return Promise.reject(new Error('Site id are required.'));
  }

  try {
    const res = await requests.page.create(new models.Page(data), account, site);

    return Promise.resolve(res.export());
  } catch (e) {
    return Promise.reject(e);
  }
}

const UpdatePage = async (core, account, data) => {
  const {
    models,
    requests
  } = core;

  if (account === undefined) {
    return Promise.reject(new Error('account id is required.'));
  }

  try {
    const res = await requests.page.update(new models.Page(data), account);

    return Promise.resolve(res.export());
  } catch (e) {
    return Promise.reject(e);
  }
}

const DeletePage = (core, page, account) => {
  const { requests } = core;

  if (account === undefined) {
    return Promise.reject(new Error('account id is required.'));
  }
  return requests.page.delete(page, account);
}

export {
  GetPage,
  CreatePage,
  UpdatePage,
  DeletePage
}
