import { pageConstants } from '../constants';

export default (state = {}, action) => {

  switch (action.type) {
    case pageConstants.REQUESTING_PAGE:
    case pageConstants.PAGE_RETRIEVED:
    case pageConstants.PAGE_SELECTED:
    case pageConstants.CLEAR_PAGE:
      return Object.assign({}, state, action);
    case pageConstants.PAGE_CLEAR:
      return {};
    default:
      return state;
  }
};
