import { sessionConstants } from '../constants';
import { App } from '../services';

import { alert } from './alert.actions';
import { loading } from './load-animation.actions';

/**
 * STATES
 */
const success = (app) => {
  return {
    type: sessionConstants.SESSION_CORE,
    core: app
  }
}

/**
 * METHODS
 */
const init = () => {
  return async (dispatch, getState) => {
    try {
      const app = await App(getState());
      return dispatch(success(app));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const app = {
  init
};
