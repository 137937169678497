import React, { Component } from 'react';
import {
  CardDeck,
  Card
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './SelectionCard.css';

class SelectionCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
    this.renderCards = this.renderCards.bind(this);
  }

  handleClick(e, i) {
    const selection = this.props.selections[i];

    return this.props.onSelected(selection);
  }

  renderCards() {
    return this.props.selections.map((s, i) => {
      return (
        <Card key={i} border="primary" style={{ width: '18rem' }} onClick={e => this.handleClick(e, i)}>
          <Card.Body>
            {s}
          </Card.Body>
        </Card>
      );
    });
  }

  render() {
    return (
      <CardDeck>
        { this.renderCards() }
      </CardDeck>
    );
  }
}

SelectionCards.propTypes = {
  selections: PropTypes.array,
  onSelected: PropTypes.func.isRequired
};

export default SelectionCards;
