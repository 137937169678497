import { alertConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
    case alertConstants.ERROR:
      return Object.assign({}, state, action);
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
};
