import { combineReducers } from 'redux';

import alert from './alert.reducer';
import app from './app.reducer';
import load from './load-animation.reducer';
import page from './page.reducer';
import session from './session.reducer';

export default combineReducers({
  alert,
  app,
  load,
  page,
  session,
});
