import config from '../config';
import { alert } from './alert.actions';
import { loading } from './load-animation.actions';
import { pageConstants } from '../constants';
import {
  SessionCore,
  GetPage
} from '../services';

const requesting = () => {
  return { type: pageConstants.REQUESTING_PAGE }
}

const retrieved = (pages, page) => {
  return {
    type: pageConstants.PAGE_RETRIEVED,
    pages,
    page
  }
}

const Clear = () => {
  return dispatch => {
    return dispatch({
      type: pageConstants.PAGE_CLEAR
    });
  };
}

const ClearPage = () => {
  return dispatch => {
    return dispatch({
      type: pageConstants.CLEAR_PAGE,
      page: undefined
    });
  };
}

const Select = (page) => {
  return dispatch => {
    return dispatch({
      type: pageConstants.PAGE_SELECTED,
      page
    });
  };
}

const Get = (id) => {
  return async (dispatch, getState) => {
    dispatch(requesting());
    try {
      const state = getState();
      const core = await SessionCore(state);
      let {
        page,
        pages
      } = state.page;
      const {
        account,
        site
      } = config;
      const res = await GetPage(core, account, site, id);

      if (Array.isArray(res)) pages = res;
      else page = res;

      return dispatch(retrieved(pages, page));
    } catch (e) {
      dispatch(loading.end());
      return dispatch(alert.error(e.message));
    }
  }
}

export const page = {
  Get,
  Clear,
  Select,
  ClearPage
};
