import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'react-bootstrap';
import {
  Masonry
} from '../../components';
import './Page.css';
import MarkdownIt from 'markdown-it'
import MarkdownAttrs from 'markdown-it-attrs';
import Parser from 'html-react-parser';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.md = new MarkdownIt({html: true});
    this.md.use(MarkdownAttrs);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.generateImage = this.generateImage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleClick(e, i) {
    e.preventDefault();
    const { page } = this.props;
  }

  updateDimensions() {
    return this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  generateImage(m, i) {
    return (
      <img
      className='portfolio-image'
      onClick={e => this.handleClick(e, i)}
      key={i}
      src={m.url} />
    );
  }

  render() {
    let { page } = this.props;
    let media;

    if (page && !page.tags.includes('nav-selection')) {
      const medias = page.media.filter(m => {
        if (m.type.includes('image')) {
          return m;
        }
      });
      media = (
        <Masonry>
          {
            medias.map((m, i) => {
              return (
                <div key={i}>
                  { this.generateImage(m, i) }
                </div>
              )
            })
          }
        </Masonry>
      );
    }

    return (
      <Row>
        <Col>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className='content-container'>
                { page && page.readme ? Parser(this.md.render(page.readme)) : null }
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              { media }
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

Page.propTypes = {
  page: PropTypes.object.isRequired
}

export default Page;
