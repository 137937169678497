export default Object.freeze({
  host: 'https://p9a4zxglrj.execute-api.us-east-1.amazonaws.com/dev',
  apiVersion: 1,
  account: '5efe7affb54ad600081ab49c',
  site: '5f08a8d773ec2200092de1e9',
  client: {
    secret: 'f9acc09729dff5e4aa3c66c8f175f952',
    id: '5f038be94a7c4c0007c48c27'
  }
});
