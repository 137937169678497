import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { page } from '../../actions';
import {
  sessionConstants,
  pageConstants
} from '../../constants';
import {
  Row,
  Col
} from 'react-bootstrap';
import {
  Masonry,
  Search
} from '../../components';
import './Projects.css';

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      pages: [],
      show: -1,
      searchDirectory: []
    };

    this.handleHover = this.handleHover.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.generateImage = this.generateImage.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.populateSearchDirectory = this.populateSearchDirectory.bind(this);
    this.populateSelections = this.populateSelections.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.clear();
    this.updateDimensions();
    this.populateSelections();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      session,
      page,
      history
    } = this.props;

    if (session.type === sessionConstants.SESSION_CORE) {
      if (page.type !== pageConstants.REQUESTING_PAGE
        && !page.pages) {
        return this.props.getPages();
      }

      if (!prevProps.page.pages && page.pages) {
        this.populateSelections();
      }

      const prevPage = prevProps.page.page;
      if (page.type === pageConstants.PAGE_SELECTED
        && prevPage !== page.page) {
        return history.push(`/${page.page.name.toLowerCase()}`);
      }
    }
  }

  populateSelections() {
    const { page } = this.props;
    let { pages } = page;
    if (pages) {
      pages = pages.reduce((a, c) => {
        if (!c.tags.includes('nav-selection') && c.enabled) a.push(c);
        return a;
      }, []);
      this.populateSearchDirectory(pages);

      this.setState({ pages });
    }
  }


  populateSearchDirectory(pages) {
    const searchDirectory = pages.reduce((a, p) => {
      if (!a[p.name.toLowerCase()]) a[p.name.toLowerCase()] = [p];

      p.keywords.forEach(k => {
        if (!a[k.toLowerCase()]) a[k.toLowerCase()] = [];
        a[k.toLowerCase()].push(p);
      });
      return a;
    }, {});

    this.setState({ searchDirectory });
  }

  updateDimensions() {
    return this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  handleClick(e, i) {
    e.preventDefault();
    const {
      page,
      select
    } = this.props;
    let { pages } = page;
    if (this.state.pages.length > 0) pages = this.state.pages;
    return select(pages[i]);
  }

  handleHover(e, i, active) {
    e.preventDefault();

    this.setState({ show: i > -1 && active ? i : -1 });
  }

  onSearchFieldChange(search) {
    const { page } = this.props;
    const { searchDirectory } = this.state;
    const pages = Object.keys(searchDirectory).reduce((a, c) => {
      if (c.includes(search.toLowerCase())) {
        const filtered = searchDirectory[c].reduce((acc, p) => {
          if (!a.includes(p)) acc.push(p);
          return acc;
        }, []);

        return a.concat(filtered);
      }
      return a;
    }, []);

    return this.setState({ pages });
  }

  generateImage(p, i) {
    const {
      width,
      show
    } = this.state;
    if (p.media.length > 0) {
      const imgClass = `portfolio-image ${ width <= 1024 || i === show ? 'portfolio-image-hover' : null}`;

      return (
        <img
        className={imgClass}
        onMouseEnter={e => this.handleHover(e, i, true)}
        onMouseOut={e => this.handleHover(e, i, false)}
        onClick={e => this.handleClick(e, i)}
        key={i}
        src={p.media[0].url} />
      );
    }
    return (
      <div className='portfolio-image'>
        <div className='portfolio-container' style={{backgroudColor: 'blue'}}>

        </div>
      </div>
    );
  }

  render() {
    const { pages } = this.state;

    if (pages) {
      return (
        <Row>
          <Col>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Search
                placeholder='Filter projects by keyword'
                onTextChange={this.onSearchFieldChange}/>
              </Col>
            </Row>
            <Row className='gallery-selection'>
              <Col>
                <Masonry>
                  {
                    pages.map((p, i) => {
                      const {
                        width,
                        show
                      } = this.state;

                      return (
                        <div key={i}>
                          { this.generateImage(p, i) }
                          { width <= 1024 || i === show ? <div className="portfolio-image-text"><h3>{p.name}</h3></div> : null }
                        </div>
                      );
                    })
                  }
                </Masonry>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  getPages: page.Get,
  select: page.Select,
  clear: page.ClearPage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
