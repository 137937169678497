import React from 'react';
import { connect } from 'react-redux';
import { page } from '../actions';
import {
  withRouter,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import {
  Page,
  ApproachPage,
  ProjectPage,
  ProjectsPage
} from '../pages';

const Routes = props => {
  const { page } = props;
  const path = props.location.pathname.split('/')[1];
  let selectedPage;

  if (page.page !== undefined) {
    selectedPage = page.page;
  }

  if (page.pages !== undefined && (!selectedPage || selectedPage.name !== path)) {
    for (let p of page.pages) {
      if (p.name.toLowerCase() === path.toLowerCase()) {
        selectedPage = p;
        break;
      }
    }
  }

  if (!selectedPage && props.location.pathname !== '/') {
    props.history.push('/');
  }

  return (
    <Switch>
      <Route exact path='/'>
        <ProjectsPage />
      </Route>
      <ReRouter page={selectedPage} path={props.location.pathname}>
        <Page page={selectedPage} />
      </ReRouter>
    </Switch>
  );
}

const ReRouter = props => {
  const { page } = props;

  return (
    <Route
      {...props.rest}
      render={({ location }) =>
        page ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  select: page.Select
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
