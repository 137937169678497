import config from '../config';
const AWE = window.AWE;

const App = (state) => {
  return new Promise(async (resolve, reject) => {
    if (state && state.session.core) {
      return resolve(state.session.core);
    }

    const {
      host,
      apiVersion,
      client
    } = config;

    try {
      const app = await AWE.sso({
        host: `${host}/v${apiVersion}`,
        clientId: client.id,
        token: client.secret,
      });

      return resolve(app);
    } catch (e) {
      return reject(e);
    }
  });
};

export {
  App
};
