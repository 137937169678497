import React from 'react';
import { connect } from 'react-redux';
import loader from './loader.svg';

const LoaderComponent = props => {
  const message = !props.load.message ? null : <h2>{props.load.message}</h2>;

  return (
    <div className="overlay">
      <div className="loader loader--style8" title="7">
        <img src={loader} alt='' />
        { message }
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps, {})(LoaderComponent);
