import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Alert,
  Loader,
  Nav,
  SideBar
} from './components';
import { connect } from 'react-redux';
import {
  Container,
  Navbar
} from 'react-bootstrap';
import QueryParser from './query-parser';
import { withRouter } from "react-router-dom";
import Routes from './routes';
import {
  app,
  loading
} from './actions';
import {
  sessionConstants,
  projectConstants,
  loadAnimationConstants
} from './constants';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  componentDidMount() {
    const {
      beginLoading,
      init
    } = this.props;
    beginLoading('Loading Portfolio...')
    return init();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      session,
      page,
      project,
      endLoading
    } = this.props;

    if (!prevProps.page.pages && page.pages) {
      return endLoading();
    }

    if (session.core) {
      return QueryParser(this.props);
    }
  }

  render() {
    const {
      alert,
      load
    } = this.props;

    return (
      <div className="App">
        <Nav brand={'Home'} homelink='/' />
        <Container fluid>
          { alert.message ? <Alert /> : null }
          { load.type === loadAnimationConstants.LOADING_BEGIN ? <Loader /> : null }
          <Routes />
        </Container>
        <Navbar className='footer' expand="lg" variant="light">
          <p className="copyright justify-content-end">© 2020 Creations By AWE</p>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  init: app.init,
  beginLoading: loading.begin,
  endLoading: loading.end
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
