import { loadAnimationConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case loadAnimationConstants.LOADING_BEGIN:
      return Object.assign({}, state, action);
    case loadAnimationConstants.LOADING_END:
      return {};
    default:
      return state;
  }
};
