export const pageConstants = {
  REQUESTING_PAGE: 'REQUESTING_PAGE',
  PAGE_RETRIEVED: 'PAGE_RETRIEVED',
  PAGE_SELECTED: 'PAGE_SELECTED',
  PAGE_CREATED: 'PAGE_CREATED',
  PAGE_UPDATED: 'PAGE_UPDATED',
  PAGE_DELETED: 'PAGE_DELETED',
  PAGE_CLEAR: 'PAGE_CLEAR',
  CLEAR_PAGE: 'CLEAR_PAGE'
};
