import queryString from 'query-string';

const queryParser = props => {
  const {
    app,
    session,
    location
  } = props;
  if (app && location) {
    const {
      pathname,
      search
    } = location;
    const queries = queryString.parse(search);

    if (!session.core) {
      switch (pathname) {
        case '/':
          const {
            activate,
            reset
          } = queries;

          if (activate) {
            props.activateCode(activate);
            return props.history.push('/login');
          } else if (reset) {
            props.recoveryCode(reset);
            return props.history.push('/reset');
          }
          return;
        default:
          return;
      }
    }
  }
}

export default queryParser;
