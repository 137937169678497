import React, {
  Component,
  forwardRef
} from 'react';
import {
  Navbar,
  Nav,
  Form,
  Dropdown,
  DropdownButton,
  Image
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  withRouter,
  Link
} from "react-router-dom";
import PropTypes from 'prop-types';
import './nav.css';
import image from './menu.svg';
import logo from './awe-white-logo.svg';
import octocat from './octocat/GitHub-Mark-Light-64px.png';
import email from './email/email-1.png';
import linkedin from './linkedin/linkedin.png';

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false
    };

    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }

  closeNav(path) {
    const { history } = this.props;

    this.setState({ navExpanded: false });
    return history.push(path);
  }

  render() {
    let {
      homelink,
      location
    } = this.props;
    homelink = homelink ? homelink : '/';

    return (
      <Navbar
      onToggle={this.setNavExpanded}
      expanded={this.state.navExpanded}
      expand="lg"
      variant="dark"
      fixed="top" >
        <Navbar.Brand>
          <Link onClick={() => this.closeNav(homelink)}>
            <img src={logo} className="logo-image" alt="settings" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav.Link className={location.pathname === "/" ? 'active-link' : null} onClick={() => this.closeNav(`/`)}>
            WORKS
          </Nav.Link>
          <Nav.Link className={location.pathname === "/about" ? 'active-link' : null} onClick={() => this.closeNav(`/about`)}>
            ABOUT
          </Nav.Link>
          <Nav.Link href="https://github.com/aa-wong" target="_blank">
            <img className='link-icons' src={octocat} alt="github" />
          </Nav.Link>
          <Nav.Link href="https://www.linkedin.com/in/aaronwongdesigns/" target="_blank">
            <img className='link-icons' src={linkedin} alt="linkedin" />
          </Nav.Link>
          <Nav.Link href="mailto:aaron@creationsbyawe.com">
            <img className='link-icons' src={email} alt="email" />
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

// <Nav.Link className={location.pathname === "/approach" ? 'active-link' : null} onClick={() => history.push(`/approach`)}>
//   APPROACH
// </Nav.Link>

const mapStateToProps = state => ({
  ...state
});

NavbarComponent.propTypes = {
  brand: PropTypes.string.isRequired,
  homelink: PropTypes.string
};

export default withRouter(connect(mapStateToProps)(NavbarComponent));
