import { appConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case appConstants.APP_INITIALIZED:
      return Object.assign({}, state, action);
    default:
      return state;
  }
};
